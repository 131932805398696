@font-face {
  font-family: 'URW DIN Arabic Thin';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin.eot');
  src:
    local('URW DIN Arabic Thin'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin.woff') format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Light';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light.eot');
  src:
    local('URW DIN Arabic Light'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light.woff') format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic XLight';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight.eot');
  src:
    local('URW DIN Arabic XLight'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight.woff')
      format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-XLight.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Regular';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular.eot');
  src:
    local('URW DIN Arabic'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular.woff')
      format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Medium';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium.eot');
  src:
    local('URW DIN Arabic Medium'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium.woff')
      format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Demi';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi.eot');
  src:
    local('URW DIN Arabic Demi'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi.woff') format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Demi.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Bold';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold.eot');
  src:
    local('URW DIN Arabic Bold'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold.woff') format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'URW DIN Arabic Black';
  src: url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black.eot');
  src:
    local('URW DIN Arabic Black'),
    local('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black.woff2')
      format('woff2'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black.woff') format('woff'),
    url('./assets/fonts/URW-DINA-Arabic/URWDINArabic-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}
