
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-crashcourse-primary: mat.define-palette(mat.$indigo-palette);
$angular-crashcourse-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-crashcourse-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-crashcourse-theme: mat.define-light-theme((
  color: (
    primary: $angular-crashcourse-primary,
    accent: $angular-crashcourse-accent,
    warn: $angular-crashcourse-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($angular-crashcourse-theme);

@import "_variables";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import '~quill/dist/quill.snow.css';
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


html,
body {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.modal-open {
  overflow: hidden;
}

textarea {
  resize: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }




